/* eslint-disable */
import * as React from 'react';
import App from '../app';
import MainLayout from '../app/main_layout';
/**
 * The `content` at the index.
 */
const UIPage = () => {
  return <MainLayout debug={true} />;
};

export default UIPage;
