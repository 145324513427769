import { StopOutlined } from '@ant-design/icons';
import { Tooltip, Slider, Button } from 'antd';
import * as React from 'react';
import PlaybackSpeedProps from './props';
import { LOCALES } from '../../../../config';
import ViewerOverlord from '../../../viewer';

export default function PlaybackSpeed({
  className,
  button: foo,
  selectedObjectName,
}: PlaybackSpeedProps) {
  // eslint-disable-next-line
  if (selectedObjectName === undefined) selectedObjectName = '';
  const maxValue: number = 4;
  const [value, setValue] = React.useState(0);
  const marks = {
    [-maxValue]: 'Bakåt',
    0: 'Stanna',
    [maxValue]: 'Framåt',
  };

  if (ViewerOverlord.getInstance() !== undefined) {
    ViewerOverlord.getInstance().setPlaybackSpeedEvent = setValue;
  }

  const onChange = (playbackSpeed: number) => {
    ViewerOverlord.getInstance().setPlaybackSpeed(playbackSpeed);
  };

  return (
    <>
      <div className={className}>
        <Slider
          step={0.01}
          marks={marks}
          min={-maxValue}
          max={maxValue}
          value={value}
          onChange={onChange}
          tooltipVisible
        />
      </div>
      <div>
        <Tooltip
          placement="top"
          title={`${
            LOCALES.MAIN_LAYOUT.CONTENT.CONTROLS.RESET
          } ${selectedObjectName.toLowerCase()}`}
        >
          <Button
            className={foo}
            onClick={() => {
              setValue(0);
              ViewerOverlord.getInstance().setPlaybackSpeed(0);
              // message.warn({
              //   content: LOCALES.MAIN_LAYOUT.CONTENT.CONTROLS.RESET_MESSAGE,
              // });
            }}
            shape="round"
            danger
            icon={<StopOutlined />}
          />
        </Tooltip>
      </div>
    </>
  );
}
