// extracted by mini-css-extract-plugin
export var app = "omnitwin-b_b";
export var header = "omnitwin-b_c";
export var sider = "omnitwin-b_d";
export var siderContent = "omnitwin-b_f";
export var appContainer = "omnitwin-b_g";
export var appLayout = "omnitwin-b_h";
export var appLayout2 = "omnitwin-b_j";
export var contentLayoutOuter = "omnitwin-b_k";
export var contentLayoutInner = "omnitwin-b_l";
export var logo = "omnitwin-b_m";
export var selectedObjectDiv = "omnitwin-b_n";