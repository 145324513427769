import * as React from 'react';
import { Layout, message } from 'antd';
import * as Styles from '../styles/index.module.less';
import '../styles/index.less';
import ViewerOverlord from '../viewer';
import DummyHeader from './dummy_header';
import DummyContent from './dummy_content';
import { LOCALES } from '../../config';
import contentOverlord from '../../config/content_overlord';
import Preloader from './preloader';

const { Header, Content } = Layout;
interface Props {
  debug: boolean;
}
export default function MainLayout({ debug }: Props) {
  const [percent, setPercent] = React.useState();
  const [lockCamera, setLockCamera] = React.useState(true); // N.B: `OrbitControls` has  `this.lockCamera = true`
  const [noclip, setNoclip] = React.useState(true); // N.B: `OrbitControls` has  `this.lockCamera = true`
  const [followContent, setFollowContent] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [hasInit, setHasInit] = React.useState(false);
  const [selectedObject, setSelectedObject] = React.useState();
  React.useEffect(() => {
    if (hasInit) return;
    message.warning({
      content: LOCALES.MAIN_LAYOUT.CONTENT.MISC.LOADING_MESSAGE,
      className: 'custom-class',
      style: {
        marginTop: '50vh',
      },
    });

    const viewer = new ViewerOverlord();
    setTimeout(async () => {
      await viewer.init(debug);
    }, 100);
    viewer.setPercent = setPercent;
    viewer.setSelectedObject = setSelectedObject;
    viewer.setHasInit = setHasInit;
    // eslint-disable-next-line
    return () => {};
  }, []);

  const { animationContent } = contentOverlord; // All animation content
  const contents = Object.values(animationContent); // As `array`
  const content = contents[contents.length - 1]; // Assume only one animation is active
  const { selectableObjects } = content;
  // eslint-disable-next-line
  let selectedObjectName: string = undefined;
  selectableObjects.forEach((i) => {
    if (i.id !== selectedObject) return;
    selectedObjectName = i.name;
  });

  return (
    <div>
      {!hasInit && <Preloader percent={percent} />}
      <Layout className={Styles.appLayout}>
        <title>Vossloh Planner</title>
        <Layout className={Styles.appLayout2}>
          {/* --- begin header content --- */}
          <Header className={Styles.header}>
            {/* --- begin header content --- */}
            <DummyHeader
              visible={visible}
              setVisible={setVisible}
              percent={percent}
              logo={Styles.logo}
              selectedObjectStyle={Styles.selectedObjectDiv}
              selectedObjectName={selectedObjectName}
            />
            {/* --- end header content --- */}
          </Header>
          {/* --- begin header --- */}
          {/* --- begin app --- */}
          <Layout id="clo" className={Styles.contentLayoutOuter}>
            <Layout className={Styles.contentLayoutInner}>
              <Content>
                {/* --- begin app content --- */}
                <DummyContent
                  lockCamera={lockCamera}
                  setLockCamera={setLockCamera}
                  noclip={noclip}
                  setNoclip={setNoclip}
                  followContent={followContent}
                  setFollowContent={setFollowContent}
                  selectedObjectName={selectedObjectName}
                />
                {/* --- end app content --- */}
              </Content>
            </Layout>
          </Layout>
          {/* --- end app --- */}
        </Layout>
      </Layout>
    </div>
  );
}
