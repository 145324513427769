import { Button } from 'antd';
import * as React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { LOCALES } from '../../../config';
import Logo from './logo';

interface DummyHeaderProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line
  percent: number;
  logo: string;
  selectedObjectStyle: string;
  selectedObjectName: string;
}
/**
 * Dummy header with custom buttons.
 * @deprecated WIP
 */
export default function DummyHeader({
  visible,
  setVisible,
  logo,
  // eslint-disable-next-line
  percent,
  selectedObjectStyle,
  selectedObjectName,
}: DummyHeaderProps) {
  const margin = 0;
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          marginLeft: margin,
        }}
      >
        <Logo logo={logo} />
      </div>
      <div>
        <div className={selectedObjectStyle}>
          <span
            style={{
              display: 'inline-block',
            }}
          >
            {selectedObjectName} {LOCALES.MAIN_LAYOUT.HEADER.SELECTED}
          </span>
        </div>
      </div>
      <div
        style={{
          marginRight: margin,
        }}
      >
        <Button
          type="primary"
          shape="round"
          icon={<ExclamationCircleOutlined />}
          onClick={() => {
            setVisible(!visible);
          }}
        >
          {LOCALES.MAIN_LAYOUT.HEADER.BUTTON_1}
        </Button>
      </div>
    </div>
  );
}
