/* eslint @typescript-eslint/indent: 0 */
import { Popover, Tree } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import * as React from 'react';
import { IDENTIFIERS, LOCALES } from '../../../config';
import ViewerOverlord from '../../viewer';
import * as Styles from './styles/index.module.less';
import DummyContentProps from './props';
import Controls from './controls';
import PlaybackSpeed from './playback_speed';

export default function DummyContent({
  lockCamera,
  setLockCamera,
  noclip,
  setNoclip,
  followContent,
  setFollowContent,
  selectedObjectName,
}: DummyContentProps) {
  const objects =
    ViewerOverlord.getInstance()?.visibilityOverlord?.getObjects();

  const treeData =
    objects === undefined
      ? []
      : Object.values(objects).map((i) => {
          return { title: i.userData.name, key: i.userData.id };
        });

  const onSelect = (selectedKeys: React.Key[], info: any) => {
    // eslint-disable-next-line
    console.log('selected', selectedKeys, info);
  };

  const onCheck = (checkedKeys: React.Key[], info: any) => {
    // eslint-disable-next-line
    console.log('onCheck', checkedKeys, info);
    ViewerOverlord.getInstance()?.visibilityOverlord?.setVisibilityForAllObjects(
      false,
    );
    checkedKeys.forEach((key) => {
      ViewerOverlord.getInstance()?.visibilityOverlord?.setVisibility(
        key as string,
        true,
      );
    });
  };
  /**
   *
   */
  const content = (
    <Tree
      defaultCheckedKeys={Object.values(treeData).map((v) => v.key)}
      checkable
      onSelect={onSelect}
      onCheck={onCheck}
      treeData={treeData}
    />
  );
  return (
    <>
      <div id={IDENTIFIERS.RENDER_AREA} className={Styles.container} />
      <Popover
        content={content}
        title={LOCALES.MAIN_LAYOUT.CONTENT.LAYERS.TITLE}
      >
        <button type="button" className={Styles.layers}>
          <div className={Styles.layersContainer}>
            <div className={Styles.layersMap} />
            <UnorderedListOutlined />
            <label className={Styles.layersLabel}>
              {LOCALES.MAIN_LAYOUT.CONTENT.LAYERS.NAME}
            </label>
          </div>
        </button>
      </Popover>
      <div id={IDENTIFIERS.PLAYBACK_SPEED} className={Styles.playbackSpeed}>
        <div className={Styles.playbackSpeedChild}>
          <PlaybackSpeed
            className={Styles.playbackSpeedSlider}
            button={Styles.playbackSpeedButton}
            selectedObjectName={selectedObjectName}
          />
        </div>
      </div>
      <div id={IDENTIFIERS.CONTROLS} className={Styles.controls}>
        <Controls
          lockCamera={lockCamera}
          setLockCamera={setLockCamera}
          noclip={noclip}
          setNoclip={setNoclip}
          followContent={followContent}
          setFollowContent={setFollowContent}
          selectedObjectName={selectedObjectName}
        />
      </div>
    </>
  );
}
